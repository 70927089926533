import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Hero from "../../../components/Hero/Hero"
import WorkWithUs from "../../../components/WorkWithUs/WorkWithUs"
import Section from "./components/Section"

import './home.scss'

const sectionsStart = [
    {
        header: "Event management",
        title: "Events unlimited.",
        text: "Our intuitive, feature rich platform gives you everything you need to make your next event a success.",
        imageUrl: "assets/images/info_menus.png",
        highlights: [
            "Simple and clear pricing structure with no upfront fees. Pay nothing if you choose to pass the charge to your guests",
            "Immediate payout - funds are transferred to your merchant account right away",
            "No event limits. Run multiple simultaneous events with ease"
        ],
    },
    {
        header: "Tickets",
        title: "Flexible ticketing to meet your needs.",
        text: "List 'em. Sell 'em. Scan 'em. We've got you covered from event inception to execution.",
        imageUrl: "assets/images/info_ticket.png",
        highlights: [
            "Hassle free attendee checkout experience",
            "Fully customisable mobile friendly ticketing (COMING SOON)",
            "On the day of your event, easily admit your guests with our simple check-in process (COMING SOON)"
        ],
    },
    {
        header: "Pre-order",
        title: "Upsell add-ons ahead of time.",
        text: "Get the party started early - sell food, drinks and more before your event kicks off!",
        imageUrl: "assets/images/info_upsell.png",
        highlights: [
            "Highly customisable and configurable",
            "Take payment or just use to collect information",
            "Keep attendees in the loop. Our customer portal shows them a comprehensive summary of their event purchases"
        ],
    },
]

const sectionsEnd = [
    {
        header: "Online ordering",
        title: "Boost sales with online ordering.",
        text: "Make ordering a breeze. Distribute QR codes for guests to order from your menus via phone or tablet!",
        imageUrl: "assets/images/info_table.png",
        highlights: [
            "Increase sales by making it incredibly easy for your guests to order from your menu",
            "Reduce queues. Let your guests order from the comfort of their own seat",
            "Access your order summary dashboard easily with our tablets, or use your own device"
        ],
    },
    {
        header: "Post-event analytics",
        title: "Rich data and insights.",
        text: "Get valuable insights and analytics to shape your future events. Let us help you optimise your strategy and make informed decisions.",
        imageUrl: "assets/images/info_stats.png",
        highlights: [
            "Track ticket sales, attendance, and revenue, and adjust your approach accordingly",
            "Recognise patterns and trends, and use them to make data-driven decisions",
            "Identify areas for improvement to optimise your event operations",
        ],
    },
    {
        header: "Security",
        title: "Security is our highest priority.",
        text: "Rest assured that we take the security of your data and your customer's data extremely seriously. We use industry standard methods of encryption to ensure your information stays safe.",
        imageUrl: "assets/images/info_secure.png",
        highlights: [
            "All payments are fully PCI compliant",
            "Your privacy is important. We will never sell any of your personal information",
            "Our secure payment processing partner ensures that all transactions are protected from fraudulent activity"
        ],
    },
]

const HomePage = () => {


    return (
        <>
            <Hero />

            <div className="home home__section__container">
                <section className="container grid--2 home__section">
                    <div>
                        <h2>Here when you need us.</h2>
                        <p>Tired of getting lost in spreadsheets and manual process? Time for a change. Let us help you save time and money with our cutting-edge event management software.</p>
                    </div>
                    <div>
                        <ul>
                            <li><FontAwesomeIcon icon={faCheck} />Unlimited menus and events</li>
                            <li><FontAwesomeIcon icon={faCheck} />Flexible QR tickets</li>
                            <li><FontAwesomeIcon icon={faCheck} />Upsell addons before the event</li>
                            <li><FontAwesomeIcon icon={faCheck} />On the day table service</li>
                            <li><FontAwesomeIcon icon={faCheck} />Post-event analytics</li>
                            <li><FontAwesomeIcon icon={faCheck} />Secure transactions</li>
                        </ul>
                    </div>
                </section>

                {sectionsStart.map((s, i) => (
                    <Section
                        key={`home-section=${i}`}
                        header={s.header}
                        title={s.title}
                        text={s.text}
                        imageUrl={s.imageUrl}
                        highlights={s.highlights}
                    />
                ))}

                <WorkWithUs />

                {sectionsEnd.map((s, i) => (
                    <Section
                        key={`home-section=${i}`}
                        header={s.header}
                        title={s.title}
                        text={s.text}
                        imageUrl={s.imageUrl}
                        highlights={s.highlights}
                    />
                ))}
            </div>
        </>
    )
}

export default HomePage
