import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Section = ({ header, title, text, imageUrl, highlights }) => {
    return (
        <section className="container">
            <div className="grid--2 home__section">
                <div>
                    <span>{header}</span>
                    <h2>{title}</h2>
                    <p>{text}</p>
                </div>
                <div>
                    <ul>
                        <img alt={title} src={imageUrl} />
                    </ul>
                </div>
            </div>
            <div className="home__section__highlights">
                <h3>Highlights</h3>
                <ul className="grid grid--3">
                    <li>
                        <FontAwesomeIcon icon={faCheck} />
                        {highlights[0]}
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faCheck} />
                        {highlights[1]}
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faCheck} />
                        {highlights[2]}
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default Section
