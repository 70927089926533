import Navbar from './components/Navbar/Navbar'
import FindOutMore from './components/FindOutMore/FindOutMore'
import Footer from './components/Footer/Footer'
import { Redirect, Route, Switch } from "react-router";
import PrivacyPolicy from "./components/privacy/PrivacyPolicy";
import TableServicePage from './pages/Features/TableService/TableService.page'
import EventsPage from './pages/Features/Events/Events.page'
import HomePage from './pages/Features/Home/Home.page'

function App() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/terms-of-service">
          <Redirect to={"/terms-of-service.html"} />
        </Route>
        <Route exact path="/terms-of-service.html" render={() => window.location.reload()} />
        <Route exact path="/website-terms">
          <Redirect to={"/website-terms.html"} />
        </Route>
        <Route exact path="/website-terms.html" render={() => window.location.reload()} />
        <Route exact path="/features/table-service" render={() => window.location.reload()}>
          <TableServicePage />
          <FindOutMore />
        </Route>
        <Route exact path="/features/events" render={() => window.location.reload()}>
          <EventsPage />
          <FindOutMore />
        </Route>
        <Route exact path="/">
          <HomePage />
          <FindOutMore />
        </Route>
        <Redirect to="/" />
      </Switch>
      <Footer />
    </>
  )
}

export default App
