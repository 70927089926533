import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import '../features-page.scss'

const TableServicePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='features-page'>
      <div className='highlights'>
        <div className="container">
          <div className="grid--2">
            <div className="left">
              <h1>Table service<br />&amp; live orders</h1>
              <p>Create and manage food and drinks menus for regular table service or one-off catering events. With an intuitive web-based portal it couldn't be any easier to manage what you sell. Best of all, we're not limiting you to a single menu, create separate menus for different bars or rooms, it's up to you.</p>
            </div>
            <div>
              <img src="../../../assets/images/acc_1.png" alt="Table service and live orders" />
            </div>
          </div>
        </div>

        <section className='container padding-top--none padding-bottom--none'>
          <span className='label label--primary'>Highlights</span>
        </section>

        <section className='container'>
          <h2>Customer ordering portal</h2>
          <div className="grid--2">
            <div className="left">
              <h3>Modern and responsive</h3>
              <p><span className='todo'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></p>
              <span className='label'>Key features</span>
              <ul>
                <li><FontAwesomeIcon icon={faCheckCircle} />Direct QR codes to the menu</li>
                <li><FontAwesomeIcon icon={faCheckCircle} />Fully responsive, allowing customers to place order on any device</li>
                <li><FontAwesomeIcon icon={faCheckCircle} />No need to download an app</li>
                <li><FontAwesomeIcon icon={faCheckCircle} />Secure card payments</li>
                <li><FontAwesomeIcon icon={faCheckCircle} />Low stock warnings</li>
                <li><FontAwesomeIcon icon={faCheckCircle} />Orders are sent straight to your kitchen</li>
              </ul>
            </div>
            <div>
              <img src="../../../assets/images/hero-orders.png" alt="Table service and live orders" />
            </div>
          </div>

        </section>

        <section className='container'>
          <h2>Kitchen portal</h2>
          <div className="grid--2">
            <div className="left">
              <h3>Live view of incoming orders</h3>
              <p><span className='todo'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></p>
              <span className='label'>Key features</span>
              <ul>
                <li><FontAwesomeIcon icon={faCheckCircle} />Live view of orders, no need to refresh the screen</li>
                <li><FontAwesomeIcon icon={faCheckCircle} />Mark orders as complete</li>
                <li><FontAwesomeIcon icon={faCheckCircle} />Delete orders</li>
                <li><FontAwesomeIcon icon={faCheckCircle} />Stock management</li>
                <li><FontAwesomeIcon icon={faCheckCircle} />Set hours during which orders can be placed</li>
              </ul>
            </div>
            <div>
              <img src="../../../assets/images/acc_1.png" alt="Table service and live orders" />
            </div>
          </div>
        </section>

        <section className='container'>
          <h2>We do more!</h2>
          <div className="grid--2">
            <div className="left">
              <p>Find out what else we can do by heading back to our <Link to="/#features">features list</Link>.</p>
            </div>
            <div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default TableServicePage
