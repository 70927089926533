import React from 'react'
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';

import './hero.scss'

const Hero = () => {
  return (
    <section className="hero">
      <div className="container grid--2">
        <div className="left">
          <h1>Event management</h1>
          <h2>Ready to plan your perfect event?</h2>
          <div className="button-group">
            <a href="#contact"><button className="button--secondary margin-right">Get in touch</button></a>
          </div>
        </div>
        <div className='slider__container'>
          <ReactBeforeSliderComponent
            firstImage={{ id: 1, imageUrl: 'assets/images/hero-products.png' }}
            secondImage={{ id: 2, imageUrl: 'assets/images/hero-orders.png' }}
          />
        </div>
      </div>
    </section>
  )
}

export default Hero
