import React from 'react'

import './footer.scss'
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="container grid--2">
        <div>
          © All rights reserved by Servd Software Limited.<br/>
          Registered in England and Wales (No 13493329).<br/>
          Our registered address is 7 Forbes Business Centre, Kempson Way, Bury St Edmunds, Suffolk, United Kingdom, IP32 7AR.<br/>
          VAT registration number 401625832.<br/>
        </div>
        <ul>
          <li><a href="/website-terms.html">Website Terms</a></li>
          <li><a href="/terms-of-service.html">Terms of Service</a></li>
          <li><Link to="/privacy-policy">Privacy Policy</Link></li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
