import React, { useRef, useState } from 'react'
import axios from 'axios'

import '../WhyUs/why-us.scss'
import './find-out-more.scss'

const FindOutMore = () => {
  const [error, setError] = useState()
  const [hasContacted, setHasContacted] = useState(false)

  const nameRef = useRef()
  const emailRef = useRef()
  const phoneRef = useRef()
  const companyRef = useRef()
  const enquiryRef = useRef()

  const sendEmail = async (e) => {
    e.preventDefault()
    setError(null)
    let isValid = true
    const errors = []

    if (!nameRef.current.value || nameRef.current.value.length === 0) {
      isValid = false
      errors.push('name')
    }

    if (!emailRef.current.value || emailRef.current.value.length === 0) {
      isValid = false
      errors.push('email address')
    }

    if (!phoneRef.current.value || phoneRef.current.value.length === 0) {
      isValid = false
      errors.push('phone number')
    }

    if (!companyRef.current.value || companyRef.current.value.length === 0) {
      isValid = false
      errors.push('company')
    }

    if (!enquiryRef.current.value || enquiryRef.current.value.length === 0) {
      isValid = false
      errors.push('enquiry')
    }

    if (!isValid) {
      setError(`Please provide your ${errors.join(', ').replace(/, ([^,]*)$/, ' and $1')}.`)
      return
    }

    try {
      await axios({
        method: 'post',
        url: 'https://api.prod.servd.uk/contact-us',
        data: {
          name: nameRef.current.value,
          company: companyRef.current.value,
          email: emailRef.current.value,
          message: enquiryRef.current.value,
          phone: phoneRef.current.value,
        },
      })
      setHasContacted(true)
    } catch (error) {
      setError('An error occurred. Try again.')
    }
  }

  return (
    <section className="why-us find-out-more" id="contact">
      <div className="container grid--2">
        <div>
          <h1>Contact us</h1>
          <p className='contact-p'>Tell us a bit more about yourself and your business by filling in the contact form or email us at <a href="mailto:hello@servd.uk?subject=Enquiry">hello@servd.uk</a> and we'll be in touch.</p>
        </div>
        <div>
          {hasContacted && (
            <>
              <h2>We're on it!</h2>
              <p>Thanks for your message. One of our team members will be in touch with you shortly.</p>
            </>
          )}
          {!hasContacted && (
            <form onSubmit={sendEmail}>
              <div className="form-container">
                <label htmlFor="company">Name</label>
                <input id="company" name="company" type="text" ref={nameRef} />

                <label htmlFor="company">Email</label>
                <input id="company" name="company" type="email" ref={emailRef} />

                <label htmlFor="company">Phone number</label>
                <input id="company" name="company" type="tel" ref={phoneRef} />

                <label htmlFor="company">Company</label>
                <input id="company" name="company" type="text" ref={companyRef} />

                <label htmlFor="company">Enquiry</label>
                <textarea id="company" name="company" rows="5" ref={enquiryRef} />
              </div>

              {!!error && (<div className="error-wrapper error-animation"><p className="error">{error}</p></div>)}
              <button className="button--secondary">Submit</button>
            </form>
          )}
        </div>
      </div>
    </section>
  )
}

export default FindOutMore
