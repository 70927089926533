import './work-with-us.scss'

const WorkWithUs = () => {
    return (
        <section className="work-with-us">
            <div className='container'>
                <h2>Work<br />with us.</h2>
                <ol className='grid grid--3'>
                    <li>
                        <span className='step__number'>1</span>
                        <h3>Step 1</h3>
                        <h4>Contact us.</h4>
                        <div>
                            <p>Let us know what you need. Fill out the form below and we'll take care of the rest.</p>
                            <a href="#contact"><button className='button--secondary'>Get in touch</button></a>
                        </div>
                    </li>
                    <li>
                        <span className='step__number'>2</span>
                        <h3>Step 2</h3>
                        <h4>Demo.</h4>
                        <div>
                            <p>One of our experienced team members will be in touch to discuss your requirements and demo our solution.</p>
                        </div>
                    </li>
                    <li>
                        <span className='step__number'>3</span>
                        <h3>Step 3</h3>
                        <h4>Create events.</h4>
                        <div>
                            <p>We'll guide you every step of the way to make sure you're getting the best value from our product.</p>
                        </div>
                    </li>
                </ol>
            </div>
        </section>
    )
}

export default WorkWithUs
