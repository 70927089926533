import React from 'react'
import './navbar.scss'

import Logo from '../common/Logo/Logo'
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="navbar-wrapper">
      <nav>
        <div className="container">
          <Link to="/">
            <Logo />
          </Link>
        </div>
      </nav>
    </div>
  )
}

export default Navbar
