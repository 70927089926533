import React from 'react'
import './logo.scss'

const Logo = () => {
  return (
    <div className="logo">
      <span>servd</span>
      <span className="highlight">.</span>
    </div>
  )
}

export default Logo
